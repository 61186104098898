import axios from "axios";
import { TSLogoDark } from "../Routes/ImagePath";
export const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

export const isAuthenticated = () => {
  return localStorage.getItem("token") !== null;
};

// Function to determine the API URL based on the current hostname
export const validateDomain = async () => {
  const domain = window.location.hostname;
  const localStorageKey = `domain_${domain}_validity`;  
  const cachedValidity = localStorage.getItem(localStorageKey);
  const expiryTime = localStorage.getItem('expiryTime'); // Get the stored expiry time
  if (expiryTime !== null) {
    
    const now = new Date().getTime(); // Get the current time in milliseconds

    if (expiryTime && now > expiryTime) {
      console.log('Time has expired.');
    } else {
      return cachedValidity === 'true';
    }
  }
  try {
    const data = await axios.get(`${getApiUrl()}/api/validate-domain`);
    // Assuming the API returns a response like { valid: true/false }
    const isValid = data.data.valid;
    console.log(data.data);
    // Step 5: Store the result in localStorage
    localStorage.setItem(localStorageKey, isValid ? 'true' : 'false');
    localStorage.setItem('isSubdomain', data.data.is_subdomain ? "true" : "false");
    if(data.data && data.data.entity_details){
      const entityDetails = data.data.entity_details;
      localStorage.setItem('entity_name', data.data.entity_details.entity_name);
      let entity_logo = "";
      try {
        entity_logo = JSON.parse(data.data?.entity_details.logo_file); // Try parsing the string
        entity_logo = entity_logo.location;
        
      } catch (e) {
        entity_logo = data.data?.entity_details.logo;
      }
      localStorage.setItem('entity_logo',entity_logo);
      localStorage.setItem('currency_symbol',entityDetails.currency_symbol);
      localStorage.setItem('currency_code',entityDetails.currency_code);
    }
    const now = new Date(); // Get the current date and time
    const expiryTime = now.getTime() + 10 * 60 * 1000; // Add 10 minutes (10 * 60 * 1000 ms)
    // Store the expiry time in localStorage
    localStorage.setItem('expiryTime', expiryTime);
    // Step 6: Return the validity result
    return isValid;
  } catch (error) {
    console.error("Error validating domain:", error);
    // Handle the error (optional)
    return false; // or you can decide how to handle failure (like returning false)
  }
  // return true;
};

// Function to determine the API URL based on the current hostname
export const getApiUrl = () => {
  const hostname = window.location.hostname; 
  // Determine the API URL based on the hostname
  let apiUrl;
  apiUrl = process.env.REACT_APP_BASE_URL;
  return apiUrl;
};

// Helper function to convert HH:MM:SS to seconds for easier comparison
export const timeToSeconds = (time) => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};
