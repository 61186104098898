/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import notifications from "../../assets/json/notifications";
import message from "../../assets/json/message";
import {
  MedExpertIcon,
  TSIcon,
  TSLogoLight,
  headerlogo,
  DefaultCompanyLogo,
} from "../../Routes/ImagePath";

import { FaRegBell, FaRegComment } from "react-icons/fa";
import { useLocation } from "react-router-dom/dist";

const Header = (props) => {
  const data = notifications.notifications;
  const datas = message.message;
  const currentUserRole = localStorage.getItem("role");
  const [notification, setNotifications] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [profile, setProfile] = useState(false);
  const [entityLogo, setEntityLogo] = useState(TSLogoLight);
  const [entityName, setEntityName] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") || null
  );
  const [dashboardLink, setDashboardLink] = useState(
    localStorage.getItem("token") ? "/dashboard" : "#"
  );
  const dropdownRef = useRef(null);

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };
  const onMenuClik = () => {
    document.body.classList.toggle("slide-nav");
  };

  const themes = localStorage.getItem("theme");
  localStorage.setItem("colorschema", "blue");
  localStorage.setItem("topbartheme", "blue");
  const BluesThemes = document.querySelector("html");

  BluesThemes.setAttribute("data-layout-mode", "blue");
  BluesThemes.setAttribute("data-topbar", "default");

  const updatedEntityData = () => {
    if (
      localStorage.getItem("isSubdomain") === "true" &&
      localStorage.getItem("entity_logo")
    ) {
      if (location.pathname !== "/") {
        setEntityLogo(localStorage.getItem("entity_logo"));
        setEntityName(localStorage.getItem("entity_name"));
      }
    }
    setTimeout(() => {
      if (!entityName) {
        updatedEntityData();
      }
    }, 100);
  };
  useEffect(() => {
    if (!entityName) {
      updatedEntityData();
    }
  }, []);

  useEffect(() => {
    const dropdownElement = dropdownRef.current;

    const handleDropdownHide = () => {
      setProfile(false); // Reset profile state when dropdown is hidden
    };

    if (dropdownElement) {
      // Attach the Bootstrap event listener
      dropdownElement.addEventListener("hide.bs.dropdown", handleDropdownHide);
    }

    return () => {
      if (dropdownElement) {
        // Clean up the event listener
        dropdownElement.removeEventListener(
          "hide.bs.dropdown",
          handleDropdownHide
        );
      }
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setNotifications(false);
    setProfile(false);
  };

  const handleNotification = () => {
    setNotifications(!notification);
    setIsOpen(false);
    setProfile(false);
  };
  const handleProfile = () => {
    setProfile(!profile);
    setNotifications(false);
    setIsOpen(false);
  };

  const location = useLocation();
  let pathname = location.pathname;
  // const { value } = useSelector((state) => state.user);
  const userData = localStorage.getItem("userData");
  const Value = JSON.parse(userData);
  const employeeId = Value?.employeeId || null;

  const ProfileName = Value?.first_name || "";
  const ProfilePic = Value?.profile_image?.file_path || "";
  const position = isLoggedIn ? "fixed" : "absolute";

  return (
    <div
      className="header"
      style={{ right: "0px", padding: "0 20px", position: position }}
    >
      {/* Logo */}
      <div className="header-left">
        <Link to={dashboardLink} className="logo">
          <img
            src={entityLogo}
            alt="img"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop in case the default image also fails
              e.target.src = DefaultCompanyLogo; // Set the default image on error
            }}
            style={{ padding: "15px", maxHeight: "60px" }}
          />
        </Link>
        <Link to={dashboardLink} className="logo2">
          <img src={TSIcon} width={40} height={40} alt="img" />
        </Link>
      </div>
      {/* /Logo */}
      <span className="separator"></span>
      {/* Header Title */}
      <div className="page-title-box">
        <h3>
          {entityName && location.pathname !== "/" ? entityName : "Advantage"}
        </h3>
      </div>
      {/* /Header Title */}
      <Link
        id="mobile_btn"
        className="mobile_btn"
        to="#"
        onClick={() => onMenuClik()}
      >
        <i className="fa fa-bars" />
      </Link>
      {/* Header Menu */}
      {isLoggedIn ? (
        <>
          <ul className="nav user-menu">
            {/* Notifications */}
            <li className="nav-item dropdown">
              <Link
                to="#"
                className="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
                onClick={handleNotification}
              >
                <i>
                  <FaRegBell />
                </i>{" "}
                <span className="badge badge-pill">3</span>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-end notifications ${
                  notification ? "show" : ""
                }`}
              >
                <div className="topnav-dropdown-header">
                  <span className="notification-title">Notifications</span>
                  <Link
                    to="#"
                    onClick={() => setNotifications(false)}
                    className="clear-noti"
                  >
                    {" "}
                    Clear All{" "}
                  </Link>
                </div>
                <div className="noti-content">
                  <ul className="notification-list">
                    {data.map((val, index) => {
                      return (
                        <li className="notification-message" key={index}>
                          <Link
                            onClick={() =>
                              localStorage.setItem("minheight", "true")
                            }
                            to="/app/administrator/activities"
                          >
                            <div className="media d-flex">
                              <span className="avatar flex-shrink-0">
                                <img alt="" src={val.image} />
                              </span>
                              <div className="media-body">
                                <p className="noti-details">
                                  <span className="noti-title">{val.name}</span>{" "}
                                  {val.contents}{" "}
                                  <span className="noti-title">
                                    {val.contents_2}
                                  </span>
                                </p>
                                <p className="noti-time">
                                  <span className="notification-time">
                                    {val.time}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="topnav-dropdown-footer">
                  <Link
                    onClick={() => localStorage.setItem("minheight", "true")}
                    to="/app/administrator/activities"
                  >
                    View all Notifications
                  </Link>
                </div>
              </div>
            </li>
            {/* /Notifications */}
            {/* Message Notifications */}
            <li className={`nav-item dropdown ${isOpen ? "show" : ""}`}>
              <Link
                to="#"
                className="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
                onClick={toggleDropdown}
              >
                <i>
                  <FaRegComment />
                </i>{" "}
                <span className="badge badge-pill">8</span>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-end notifications ${
                  isOpen ? "show" : ""
                }`}
              >
                <div className="topnav-dropdown-header">
                  <span className="notification-title">Messages</span>
                  <Link to="#" className="clear-noti">
                    {" "}
                    Clear All{" "}
                  </Link>
                </div>
                <div className="noti-content">
                  <ul className="notification-list">
                    {datas.map((value, index) => {
                      return (
                        <li className="notification-message" key={index}>
                          <Link
                            onClick={() =>
                              localStorage.setItem("minheight", "true")
                            }
                            to="/conversation/chat"
                          >
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">
                                  <img alt="" src={value.image} />
                                </span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  {value.name}
                                </span>
                                <span className="message-time">
                                  {value.time}
                                </span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  {value.contents}
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="topnav-dropdown-footer">
                  <Link
                    onClick={() => localStorage.setItem("minheight", "true")}
                    to="/conversation/chat"
                  >
                    View all Messages
                  </Link>
                </div>
              </div>
            </li>
            {/* /Message Notifications */}
            <li
              className="nav-item dropdown has-arrow main-drop"
              ref={dropdownRef}
            >
              <Link
                to="#"
                className="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
                onClick={handleProfile}
              >
                {" "}
                <span className="user-img me-1 p-1">
                  <img src={ProfilePic} alt="img" />
                  <span className="status online" />
                </span>
                <span>{ProfileName ? `${ProfileName}` : "Admin"}</span>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-end ${
                  profile ? "show" : ""
                }`}
              >
                {!["super-admin", "sales-head", "sales-rep"].includes(
                  currentUserRole
                ) &&
                  employeeId && (
                    <Link
                      className="dropdown-item"
                      to={`profile/${employeeId}`}
                    >
                      My Profile
                    </Link>
                  )}
                {currentUserRole === "tenant-admin" && (
                  <Link className="dropdown-item" to="/company-details/1">
                    Company Profile
                  </Link>
                )}
                <Link className="dropdown-item" to="/change-password">
                  Change Password
                </Link>
                <Link className="dropdown-item" to="/logout">
                  Logout
                </Link>
              </div>
            </li>
          </ul>
          {/* /Header Menu */}
          {/* Mobile Menu */}
          <div className="dropdown mobile-user-menu">
            <Link
              to="#"
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v" />
            </Link>
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-right">
              {![
                "super-admin",
                "sales-head",
                "sales-rep",
                "tenant-admin",
              ].includes(currentUserRole) &&
                employeeId && (
                  <Link className="dropdown-item" to={`profile/${employeeId}`}>
                    My Profile
                  </Link>
                )}
              {currentUserRole === "tenant-admin" && (
                <Link className="dropdown-item" to="/company-details/1">
                  Company Profile
                </Link>
              )}
              <Link className="dropdown-item" to="/change-password">
                Change Password
              </Link>
              <Link className="dropdown-item" to="/logout">
                Logout
              </Link>
            </div>
          </div>
          {/* /Mobile Menu */}
        </>
      ) : (
        <>
          <ul className="nav user-menu">
            <li className={`nav-item`}>
              <Link to="/login" className="nav-link">
                Login
              </Link>
            </li>
            <span className="separator"></span>
            <li className={`nav-item`}>
              <Link to="#" className="nav-link">
                Support
              </Link>
            </li>
          </ul>
          {/* /Header Menu */}
          {/* Mobile Menu */}
          <div className="dropdown mobile-user-menu">
            <Link
              to="#"
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v" />
            </Link>
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-right">
              <Link className="dropdown-item" to="/login">
                Login
              </Link>
              <Link className="dropdown-item" to="#">
                Support
              </Link>
            </div>
          </div>
          {/* /Mobile Menu */}
        </>
      )}
    </div>
  );
};

export default Header;
